import React, { useState } from 'react';
import { Grid, IconButton, Input, Button, TextField, Tooltip, Switch } from '@material-ui/core'
import { Delete, Archive, Pageview, LockOpen, Lock } from '@material-ui/icons'

export default function File({
	sDOCID,
	fileID,
	mountType,
	originalFileName,
	fileName,
	// fileDate,
	onDownloadDocument,
	onDelete,
	index = 0,
	isFileLocked,
	onDeleteUploadedDocument,
	onChangeAttachedFileName,
	onChangeIsFileLocked,
	ApprovalControl,
	sDOCStatus,
	isEditing,
}) {
	const [FileName, setFileName] = useState(fileName);
	const handleFileNameChange = (e) => {
		setFileName(e.target.value);
	};

	const [showFullFileNames, setShowFullFileNames] = useState({});

	const toggleFileName = (id) => {
		setShowFullFileNames((prevState) => ({
			...prevState,
			[id]: !prevState[id],
		}));
	};

	const truncate = (str, maxLength) => {
		return str.length > maxLength ? str.substring(0, maxLength) + '...' : str;
	};

	return (
		<Grid
			container
			alignItems="center"
			spacing={1}
			className="flex flex-row gap-x-3 w-full items-center"
			style={{
				display: 'flex',
				flexWrap: 'nowrap', // Prevent wrapping
				alignItems: 'center',
				gap: '16px', // Consistent spacing between items
			}}
		>
			{/* Index Section */}
			<Grid item style={{ flexShrink: 0 }}>
				{index + 1}.)
			</Grid>

			{/* File Name Section */}
			<Grid item style={{ flex: 3, minWidth: '0' }}>
				{isEditing ? (
					<div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
						<TextField
							fullWidth
							variant="outlined"
							size="small"
							inputProps={{ maxLength: 200 }}
							value={FileName}
							onChange={handleFileNameChange}
							label="โปรดตั้งชื่อเอกสาร*"
						/>
						<Button variant="contained" color="primary">
							บันทึก
						</Button>
					</div>
				) : (
					<>ชื่อเอกสาร : {FileName}</>
				)}
			</Grid>

			{/* Original File Name Section */}
			<Grid item style={{ flexShrink: 0 }}>
				<Tooltip title={originalFileName} arrow>
					<span
						style={{ cursor: 'pointer' }}
						onClick={() => toggleFileName(fileID)}
					>
						({showFullFileNames[fileID] ? originalFileName : truncate(originalFileName, 25)})
					</span>
				</Tooltip>
			</Grid>

			{/* File Actions Section */}
			{mountType && fileID && (
				<Grid
					item
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '8px',
						flexShrink: 0,
						whiteSpace: 'nowrap', // Prevent wrapping
					}}
				>
					<a
						href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${sDOCID}/attach/${fileID}/download`}
						target="_blank"
						rel="noreferrer"
					>
						<IconButton color="primary" onClick={onDownloadDocument}>
							<Archive />
						</IconButton>
					</a>
					<a
						href={`${process.env.REACT_APP_PUBLIC_API_URL}/api/SDoc/${sDOCID}/attach/${fileID}/View`}
						target="_blank"
						rel="noreferrer"
					>
						<IconButton color="primary" onClick={onDownloadDocument}>
							<Pageview />
						</IconButton>
					</a>
					{((mountType === 'ModifyInfo' ||
						mountType === 'attachFile' ||
						(sDOCStatus &&
							sDOCStatus === 2 &&
							ApprovalControl &&
							ApprovalControl === 'true'))) &&
						isFileLocked === false && (
							<IconButton color="primary" onClick={onDeleteUploadedDocument}>
								<Delete />
							</IconButton>
					)}
					{((mountType === 'ModifyInfo' ||
						mountType === 'attachFile' ||
						(sDOCStatus &&
							sDOCStatus === 2 &&
							ApprovalControl &&
							ApprovalControl === 'true'))) &&
						isFileLocked === true && (
							<IconButton color="primary" disabled>
								<Delete />
							</IconButton>
					)}
				</Grid>
			)}

			{/* Delete and Additional File Section */}
			{(!mountType ||
				(mountType &&
					(mountType === 'ModifyInfo' || mountType === 'attachFile')) &&
				!fileID) && (
					<Grid item style={{ flexShrink: 0 }}>
						<IconButton color="primary" onClick={onDelete}>
							<Delete />
						</IconButton>
						(เอกสารที่แนบเพิ่มเติม)
					</Grid>
				)}

			{/* Lock/Unlock Section */}
			{ApprovalControl && ApprovalControl === 'true' && (
				<Grid item style={{ flexShrink: 0 }}>
					{isFileLocked === true ? (
						<IconButton color="primary" onClick={onChangeIsFileLocked}>
							<Lock />
						</IconButton>
					) : (
						<IconButton color="primary" onClick={onChangeIsFileLocked}>
							<LockOpen />
						</IconButton>
					)}
				</Grid>
			)}
		</Grid>
	)
}
